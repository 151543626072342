import $ from "jquery";
import ClipboardJS from "clipboard";
import { initAutofit } from "./autofit";
import {
	getJson,
	generateDynamicLink,
	// fetchAlltoCache,
	postVisitorLog,
	postSumbangAmal,
	checkLuckyDraw,
	postAnswerLog
} from "./services";

import { base32 } from "./base32";
// import { make_copy_button } from "./copy2clipboard";
import FacebookImage from './assets/images/icon-fb.png';
import LineImage from './assets/images/icon-line.png';
import TwitterImage from './assets/images/icon-twitter.png';
import WhatsappImage from './assets/images/icon-wa.png';

import {
	findGetUrlParameter,
	setUrlParameter,
	shuffleArray,
	getHintAnswer,
	getClientCookie,
	setClientCookie,
	isAtDownloadPage,
 } from "./helper";

 import {
	sendWelcomeScreenviewGA,
	sendWelcomeEventGA,
	sendGameplayScreenGA,
	sendClickShareGA,
	sendClickOnInputGA,
	sendClickOnAnswerGA,
	sendLoadingScreenviewGA,
	sendErrorScreenviewGA,
	sendClickTryAgainGA,
	sendEndingScreenviewGA,
	sendClickContinueGA
} from "./tracker";

let currentCategory = {
	id: 0,
	title: "",
	backgroundUrl: "",
	backgroundColor: "",
	popUpImageTrueUrl: "",
	popUpImageFalseUrl: "",
	shareText: "",
	identifier: "",
	welcomeScreen: {
		imageUrl: "",
		caption: "",
		buttonTitle: ""
	},
	questions: [
		{
			id: 0,
			url: "",
			coverImageUrl: "",
			caption: "",
			answer: ""
		}
	],
	endingScreen: {
		imageUrl: "",
		caption: "",
		buttonTitle: "",
		buttonUrl: ""
	}
};
let home = {};
let luckyDraw = {};
let shareAtHome = {};

let shareLink = {
	general: {
		fb: "",
		line: "",
		twitter: "",
		whatsapp: ""
	},
	question: {
		fb: "",
		line: "",
		twitter: "",
		whatsapp: ""
	}
}

export const PAGE_TYPE_HOME = 1;
export const PAGE_TYPE_WELCOME = 2;
export const PAGE_TYPE_QUESTION = 3;
export const PAGE_TYPE_ENDING = 4;
export const PAGE_TYPE_ERROR = 5;
export const PAGE_TYPE_LOADING = 6;
export const PAGE_ALREADY_PLAYED = 7;
export const PAGE_LUCKY_DRAW = 8;

const SocMed = {
	FACEBOOK: 'fb',
	TWITTER: 'twitter',
	LINE: 'line',
	WHATSAPP: 'whatsapp'
}

let thisPage = {
	currentScrollTop: 0,
	currentPage: PAGE_TYPE_HOME,
	currentQuestionID: 0,
	categoryCount: 0,
	questionCount: 0,
	answerOnFocus: false,
	clipboard: null,
};

let participant = {
	user_id: 0,
	client_id: 0, // Gojek user_id
	name: "Sogam",
	is_already_issued: false,
	lives: 0,
	hint: {
		question_id: 0,
		generated_hint: '',
	},
};

let template = {
	component: {
		luckyDraw: $("#lucky-draw-component").text(),
		modal: $("#modal-component").text(),
	},

	page: {
		luckyDraw: $("#lucky-draw-page").text(),
		welcome: $("#welcome-page").text(),
		error: $("#error-page").text(),
		alreadyPlayed: $("#already-played-page").text(),
		ending: $("#ending-page").text(),
		share: $("#share-page").text(),
		question: $("#question-page").text(),
		loader: $("#loader-page").text(),
		download: $("#download-page").text(),
	}
};

if ("serviceWorker" in navigator) {
	window.addEventListener("load", function() {
		navigator.serviceWorker.register("service-worker.js").then(
			function(registration) {
				// Registration was successful
				console.log( "ServiceWorker registration successful: ", registration);
			},
			function(err) {
				// registration failed :(
				throw new Error("ServiceWorker registration failed: ", err);
			}
		);
	});
}


$(document).ready(function() {
	console.clear();
	if (isAtDownloadPage()) {
		return renderDownloadPage();
	}

	sendLoadingScreenviewGA();
	renderLoading();

	if (findGetUrlParameter("username")) {
		participant.name = findGetUrlParameter("username");
	}

	// get participant lives from cookie
	participant.lives = getClientCookie('client_lives')
	if (!participant.lives) {
		participant.lives = 5
		setClientCookie('client_lives', participant.lives, 30)
	}

	fetchJson();

	if (navigator.platform == "iPad" ||
		navigator.platform == "iPhone" ||
		navigator.platform == "iPod" ||
		navigator.platform == "Linux armv6l"
	) {
		$(document).on('focus', 'textarea', function(e) {
			$('.tg__answer').css({
				position: 'absolute',
			})

		}).on('blur', 'textarea', function(e) {
			$('.tg__answer').css({
				position: 'fixed',
			})
		});

		/*
		window.onscroll = function() {
			if (thisPage.currentPage == PAGE_TYPE_QUESTION) {
				return;
			}

			var iPadPosition = window.innerHeight + window.pageYOffset - 63; // 45 is the height of the Footer
			$(".tg__answer").css("position", "absolute");
			$(".tg__answer").css("top", iPadPosition);
		};
		*/

		/*
		document.ontouchmove = function(e){
			// e.preventDefault();
		}
		*/
  
		/*
		$(document).on('focus', 'textarea', function(e) {
			thisPage.answerOnFocus = true;
			let questionHeight = 
				$('.tg__question--image').height() +
				$('.tg__share').height() +
				$('.tg__question--caption').height() +
				$('.tg__answer').height()
			;

			$('.tg__question').css({
				height: questionHeight,
			})

		}).on('blur', 'textarea', function(e) {
			if (!thisPage.answerOnFocus) {
				return;
			}

			thisPage.answerOnFocus = false;
			$('.tg__answer').css({
				position: 'fixed',
				top: 'unset',
				bottom: 0,
			});
		});
		*/

		/*
		$(window).scroll(function() {
			if (!thisPage.answerOnFocus) {
				return;
			}

			let scrollTop = $(this).scrollTop();
			let questionHeight =
				$('.tg__question--image').height() +
				$('.tg__share').height() +
				$('.tg__question--caption').height()
			;

			if (scrollTop < questionHeight) {
				$(".tg__answer").css({
					position: 'absolute',
					// top: questionHeight + scrollTop - 50 - 21 - 45,
					top: questionHeight + scrollTop - 50 - 21 - 93,
					bottom: 'unset',
				})
	
			} else if (scrollTop > thisPage.currentScrollTop) {
				$('html, body').animate({
					scrollTop: questionHeight - 50,
				});
			}

			thisPage.currentScrollTop = scrollTop;
		});
		*/
	
	} else {
		/*
		$(document).on('focus', 'textarea', function(e) {
			let sh = screen.height
			let dh = window.innerHeight

			$('#wrapper').css({
				// height: 'calc(100vh + 200px)',
				// height: dh + (sh - dh),
				// height: 
			});

		}).on('blur', 'textarea', function(e) {
			$('#wrapper').css({
				height: '100vh',
			});
		});
		*/
	}
});

function initiateGA() {
	participant.user_id = window.ga && window.ga.getAll ? window.ga.getAll()[0].get('clientId') : 0;
}

function renderPage(html) {
	$("#wrapper").empty();
	$("#wrapper").append(html);
}

function renderDialog(html) {
	$("#dialog").empty();
	$("#dialog").append(html);
}

function closeDialog() {
	setTimeout(() => {
		renderDialog('');
	}, 500);
}

function fetchJson() {
	let identifier = findGetUrlParameter('identifier');
	getJson({
		identifier: identifier ? identifier : null,
		token: getToken()
	}).then(response => {
		return response.json();
	}).then(json => {
		if (!json.data) {
			renderErrorAndThrow(`Data does not exists`);
		}
		if (!json.data.category) {
			return showUpdateApps();
		}

		setupProperty(json.data);
		sendWelcomeScreenviewGA(() => {
			initiateGA();
			sendVisitorLog();
		});
		setupPage(json.data);
	}).catch(function(error) {
		console.log(error);
		// alert(error);
		renderError();
	});
}

function setupProperty(data) {
	setCurrentCategory(data);
	if (!data.home) { renderErrorAndThrow(`home is empty`); }
	home = data.home;

	if (!data.lucky_draw) { renderErrorAndThrow(`Lucky draw is empty`); }
	luckyDraw = data.lucky_draw;

	shareAtHome = data.share;
	participant.client_id = data.user.id ? data.user.id : 0;
	setupShareLink();
}

function setupPage() {
	if (!home.is_can_play) {
		return renderModal(home.popup.message, home.popup.button_text, `modalButtonClicked('${home.popup.button_url}')`);
	}

	renderLuckyDraw();
	if (home.is_already_play) {
		return renderAlreadyPlayed();
	}
	renderWelcome();
}

function setCurrentCategory(data) {
	currentCategory = data.category;
	currentCategory.questions = shuffleArray(currentCategory.questions);

	if (!currentCategory.popUpImageTrueUrl) {
		currentCategory.popUpImageTrueUrl =
			"https://static.tebakgambar.com/TG-Lite/default/image-right.png";
	}

	if (!currentCategory.popUpImageFalseUrl) {
		currentCategory.popUpImageFalseUrl =
			"https://static.tebakgambar.com/TG-Lite/default/image-wrong.png";
	}

	let questionImages = [];
	questionImages.push(currentCategory.popUpImageTrueUrl);
	questionImages.push(currentCategory.popUpImageFalseUrl);
	currentCategory.questions.forEach(question => {
		questionImages.push(question.url);
	});

	// fetchAlltoCache(questionImages);
	document.title = currentCategory.title;

	/* DISABLE TEMPORARY
	window.history.pushState({
		"pageTitle": currentCategory.title,
	},"", `/category/${currentCategory.id}`);
	*/
}

function getQuestion(id) {
	if (currentCategory.questions.length < id) {
		throw new Error(`Cannot get question, it does not exists`);
	}

	return currentCategory.questions[id - 1];
}

function pushUrlParam() {
	let paramValue = ''

	switch(thisPage.currentPage) {
		case PAGE_TYPE_HOME:
			paramValue = `home-${currentCategory.id}`
			break;

		case PAGE_TYPE_QUESTION:
			paramValue = `q-${thisPage.questionCount}-${currentCategory.id}-${thisPage.currentQuestionID}`
			break;

		case PAGE_TYPE_ENDING:
			paramValue = `ending-${currentCategory.id}`
			break;

		default:
			paramValue = `home-${currentCategory.id}`
	}

	let urlParam = setUrlParameter('pagename', paramValue)

	// push url param into history state
	window.history.pushState({
		"pageTitle": currentCategory.title,
	}, "", `?${urlParam}`)
}

function renderLoading() {
	thisPage.currentPage = PAGE_TYPE_LOADING;
	let html = template.page.loader;
	renderPage(html);
}

function renderTransparentLoading() {
	thisPage.currentPage = PAGE_TYPE_LOADING;
	
	$("#modal").empty();
	$("#modal").append(template.page.loader);
	$("#myLoader").addClass("modal");
}

function renderErrorAndThrow(message) {
	renderError();
	// alert(message);
	throw new Error(message);
}

function renderError() {
	thisPage.currentPage = PAGE_TYPE_ERROR;
	let image_url = "https://lh3.googleusercontent.com/hsE_7lFQ_LDj7N-kULxRyYtyydoaGo2DDVxVaKd8YQm41ASWj_epPyo5WjXydi-EBGsK_ip_gXx8bzr2DqbOP2NtoYzCK3qU1JFBcBlt-1WdwrE01ICfHFa5iKHSCPs-NJxV14mF7g=s400-no";
	let caption = `Wadaw, server lagi kebingungan!\nAyo coba lagi ya ${participant.name}, plis ...`;

	let html = template.page.error
		.replace("$reload_image_url", image_url)
		.replace("$image_url", image_url)
		.replace("$caption", caption)
		.replace("$onclick", "sendClickTryAgainGA(); location.reload(true)")
		.replace("$button", "Coba Lagi >")
	;

	sendErrorScreenviewGA();
	renderPage(html);
}

function renderWelcome() {
	thisPage.currentPage = PAGE_TYPE_WELCOME;
	let welcomeScreen = currentCategory.welcomeScreen;
	let caption = (welcomeScreen.caption && welcomeScreen.caption !== "null") 
		? welcomeScreen.caption
		: "";
	caption += caption ? + "<br/>" + home.additional_text : home.additional_text;

	$("body").css("background-image", `url(${currentCategory.backgroundUrl})`);
	$("body").css(
		"background-color",
		`url(${currentCategory.backgroundColor})`
	);

	let html = template.page.welcome
		.replace("$image_url", welcomeScreen.imageUrl)
		.replace("$reload_image_url", welcomeScreen.imageUrl)
		.replace("$caption", caption)
		.replace("$onclick", "renderQuestion()")
		.replace("$button", welcomeScreen.buttonTitle)
		.replace("$tncclick", "tncClick()")
		.replace("$user", participant.name)
	;

	pushUrlParam();
	renderPage(html);
}

function renderAlreadyPlayed() {
	thisPage.currentPage = PAGE_ALREADY_PLAYED;
	let welcomeScreen = currentCategory.welcomeScreen;
	let caption = (welcomeScreen.caption && welcomeScreen.caption !== "null") 
		? welcomeScreen.caption
		: "";
	caption += caption ? + "<br/>" + home.additional_text : home.additional_text;

	$("body").css("background-image", `url(${currentCategory.backgroundUrl})`);
	$("body").css(
		"background-color",
		`url(${currentCategory.backgroundColor})`
	);

	let html = template.page.alreadyPlayed
		.replace("$image_url", welcomeScreen.imageUrl)
		.replace("$reload_image_url", welcomeScreen.imageUrl)
		.replace("$caption", caption)
		.replace("$share_page", getShareTextTemplate())
		.replace("$button", welcomeScreen.buttonTitle)
		.replace("$share_title", shareAtHome.title)
		.replace("$already_played_text", home.already_play_text)
		.replace("$tncclick", "tncClick()")
		.replace("$user", participant.name)
	;

	renderPage(html);
}

function renderLuckyDraw()  {
	let html = template.component.luckyDraw
		.replace("$lucky_draw_image_url", luckyDraw.button_image_url)
		.replace("$onclick", "luckyDrawClicked()")
	;

	$("#lucky-draw").empty();
	$("#lucky-draw").append(html);
}

function renderModal(message, buttonText, onClickAction="")  {
	let html = template.component.modal
		.replace("$message", message)
		.replace("$onclick", onClickAction ? onClickAction : "closeModal()")
		.replace("$button", buttonText);

	$("#modal").empty();
	$("#modal").append(html);
}

function renderDownloadPage() {
	if(findGetUrlParameter("from_identifier")) {
		String.prototype.splice = function(idx, rem, str) {
			return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
		};
	
		var identifier = base32.decode(findGetUrlParameter("from_identifier")).splice(12, 0, "landingPage_");
		console.log('identifier');
		postVisitorLog(identifier);
	}
	let imageUrl = "https://static.tebakgambar.com/TG-Lite/01062020-BawaSemangat/icon-home/landing-page-banner.gif";
	let downloadLink = "https://maintebakgambar.page.link/6XPC";
	let html = template.page.download
		.replace("$image_url", imageUrl)
		.replace("$reload_image_url", imageUrl)
		.replace("$caption", "Kamu dan temen kamu akan berkesempatan untuk dapet hadiah menarik kalau kamu bantu dia dengan download game Tebak Gambar dan mainin level #BawaSemangat!")
		.replace("$onclick", `window.open('${downloadLink}');`)
		.replace("$button", "Download")
	renderPage(html);
}

function closeModal() {
	$("#modal").empty();
}

function renderQuestion() {
	$("#lucky-draw").empty();
	thisPage.questionCount++;
	setTimeout(() => {
		renderLoading();
	}, 500);

	if (thisPage.questionCount == 1) {
		sendWelcomeEventGA(currentCategory.welcomeScreen.buttonTitle);
	}

	let currentQuestion = getQuestion(thisPage.questionCount);
	thisPage.currentQuestionID = currentQuestion.id;
	let html = template.page.question
		.replace("$reload_image_url", currentQuestion.url)
		.replace("$image_url", currentQuestion.url)
		.replace("$caption", currentQuestion.caption)
		.replace("$user", participant.name)
		.replace("$share_page", getShareTextTemplate(true))
		.replace("$share_text", 'BINGUNG? KUY TANYA TEMEN')
	;

	if (!home.is_already_play) {
		generateShareLink(true);
	}

	setTimeout(() => {
		thisPage.currentPage = PAGE_TYPE_QUESTION;
		pushUrlParam();
		sendGameplayScreenGA();
		renderPage(html);
		thisPage.clipboard = new ClipboardJS('.tg__share-text');

		$('#wrapper').addClass('wrapper__question');
		$('body, .tg__answer').css('background-color', `${currentCategory.backgroundColor}`);

		initAutofit();
	}, 1000);

	// $('#user-answer').focus();
}

// Mark: Action
function modalButtonClicked(url) {
	if (url === "") { return $("#modal").empty(); }
	window.open(url);
}

function luckyDrawClicked() {
	if (!luckyDraw.is_eligible_lucky_draw) {
		return renderModal(luckyDraw.popup.message, luckyDraw.popup.button_text);
	}
	renderLuckyDrawPage();
}

function tncClick() {
	window.open(luckyDraw.screen.tnc.url);
}

function checkAnswer() {
	// let sh = screen.height
	// let dh = window.innerHeight
	// let vh = $('#wrapper').height()
	// alert(`sh: ${sh}, dh: ${dh}, vh: ${vh}`)


	let userAnswer = $("#user-answer").val().toLowerCase().trim().replace(/\s\s+/g, " ");
	$("#user-answer").blur();

	if ($('.tg__answer--button').hasClass('tg__answer--button-disabled') || userAnswer == "") {
		return false;
	}

	let currentQuestion = getQuestion(thisPage.questionCount);
	let currentAnswer = currentQuestion.answer.toLowerCase();
	let isTrueOrFalse = currentAnswer == userAnswer ? "true" : "false";
	let popUpImage = currentAnswer == userAnswer ? currentCategory.popUpImageTrueUrl : currentCategory.popUpImageFalseUrl;
	let html = `<div class="dialog__popup-answer"><img src="${popUpImage}"></div>`;

	$(".tg__answer--button").addClass(
		"tg__answer--button-disabled"
	);

	sendClickOnAnswerGA(isTrueOrFalse);
	postAnswerLog(isTrueOrFalse);

	setTimeout(() => {
		renderDialog(html);
	}, 200);

	setTimeout(() => {
		renderDialog("");

		if (currentAnswer == userAnswer) {
			if (thisPage.questionCount >= currentCategory.questions.length) {
				allQuestionsAnsweredAndSumbangAmal();
			} else {
				renderQuestion();
			}
		}

		$(".tg__answer--button").removeClass('tg__answer--button-disabled');

	}, 1000);

	return false;
}

function allQuestionsAnsweredAndSumbangAmal() {
	renderTransparentLoading();
	postSumbangAmal({
		categoryId: currentCategory.id, 
		token: getToken()
	})
		.then(response => { return response.json(); })
		.then(json => {
			closeModal();
			if (!json.data) { throw new Error(`Failed sumbang`); }
			renderEnding();
			if (json.data.additional_text && json.data.additional_text !== "null") {
				renderModal(json.data.additional_text, "OK")
			}
		}).catch(function(error) {
			console.log(error);
			let message = "Yah, ada yang salah nih dengan server Tebak Gambar. Ayo Coba Lagi!";
			renderModal(message, "COBA LAGI", "allQuestionsAnsweredAndSumbangAmal()")
		});
}

function renderEnding() {
	thisPage.currentPage = PAGE_TYPE_ENDING;

	let endingScreenCategory = currentCategory.endingScreen;
	
	let buttonTitle = luckyDraw.is_eligible_lucky_draw ? luckyDraw.ending_screen.button_text : endingScreenCategory.buttonTitle;
	let onClickAction = luckyDraw.is_eligible_lucky_draw
		? "renderLuckyDrawPage();" 
		: `goToUrl('${endingScreenCategory.buttonUrl}'); fetchJson();`;
	let html = template.page.ending
		.replace("$reload_image_url", endingScreenCategory.imageUrl)
		.replace("$image_url", endingScreenCategory.imageUrl)
		.replace("$caption", endingScreenCategory.caption)
		.replace(
			"$onclick",
			`sendClickContinueGA('${buttonTitle}'); ${onClickAction}`
		)
		.replace("$button", buttonTitle)
		.replace("$user", participant.name)
		.replace("$share_page", getShareTextTemplate())
		.replace("$share_text", 'BAGIIN KE TEMEN KAMU')
	;

	pushUrlParam();
	renderPage(html);
	sendEndingScreenviewGA();

	thisPage.clipboard = new ClipboardJS('.tg__share-text');

	$('#wrapper').removeClass('wrapper__question');
}

function renderLuckyDrawPage(objToFill = {}) {
	if (!luckyDraw.is_eligible_lucky_draw) {
		return console.log('unable renderLuckyDrawPage');	
	}

	let luckyDrawValue = luckyDraw.screen.result ? luckyDraw.screen.result : luckyDraw.screen.default;

	if (objToFill.image_url) {
		luckyDrawValue = objToFill;
	}

	let msgResult = `<div>${luckyDrawValue.message}</div>`;

	if (luckyDrawValue && luckyDrawValue.action && luckyDrawValue.action.url) {
		const winnerUrl = `${luckyDrawValue.action.url}?token=${getToken()}`;
		msgResult += `
			<div class="tg__button-container">
				<a class="tg__button" href="${winnerUrl}">${luckyDrawValue.action.text}</a>
			</div>
		`;
	}

	const html = template.page.luckyDraw
		.replace('$reload_image_url', luckyDrawValue.image_url)
		.replace('$image_url', luckyDrawValue.image_url)
		.replace('$message', msgResult)
		.replace("$share_text", 'KASIH TAU TEMEN KAMU')
		.replace("$share_page", getShareTextTemplate())
		.replace('$tnc_title', luckyDraw.screen.tnc.title)
		.replace("$tncclick", "tncClick()")
	;

	renderPage(html);

	$("#lucky-draw").empty();
}

function handleCheckLuckyDraw() {
	if (participant.is_already_issued || luckyDraw.screen.result) {
		return;
	}

	renderLoading();

	checkLuckyDraw(getToken(), currentCategory.id)
	.then(response => { return response.json(); })
	.then(json => {
		renderLuckyDrawPage(json.data);

	}).catch(function(error) {
		console.log(error);
		let message = "Yah, ada yang salah nih dengan server Tebak Gambar. Ayo Coba Lagi!";
		renderModal(message, "COBA LAGI", "handleCheckLuckyDraw()")
	});

	$('#lucky-draw-page .tg__image img').val();
	participant.is_already_issued = true;
}

function removePlaceholder() {
	$('.tg__image img').css('background', 'unset');
	$('.tg__image--placeholder').remove();
	$(".tg__image img").css("display", "unset");
}

function goToUrl(link) {
	setTimeout(() => {
		renderLoading();
		window.open(link);
	}, 500);
}

// function getShareLink(utm_medium) {
// 	let link_to_share = `https://m.tebakgambar.com/sharer.php?p=`;
// 	let identifier = '';
// 	let question_id = thisPage.currentPage == PAGE_TYPE_ENDING ? 0 : thisPage.currentQuestionID;

// 	identifier += `${currentCategory.identifier}`;
// 	identifier += `-${currentCategory.id}`;
// 	identifier += `-${question_id}`;
// 	identifier += `-${participant.user_id}`;
// 	identifier += `-${participant.name.replace(/-/g, "_")}`;
// 	identifier += `-${utm_medium}`;

// 	link_to_share += base32.encode(identifier);
// 	return link_to_share;
// }
// 
// function showHintButton() {
// 	if (participant.lives < 1) {
// 		return
// 	}

// 	const currentQuestion = getQuestion(thisPage.questionCount)
// 	const generatedHint = getHintAnswer(currentQuestion.answer)

// 	// decrement participant lives
// 	participant.lives = participant.lives - 1
// 	setClientCookie('client_lives', participant.lives, 30)

// 	let html =
// 		`<div class="overlay"></div>` +
// 		`<div class="dialog">` +
// 			`<div class="dialog__content">` +
// 				`<div class="dialog__title">Let me help you ...</div>` +
// 				`<div class="dialog__hint">${generatedHint}</div>` +
// 			`</div>` +
// 		`</div>`
// 	;

// 	setTimeout(() => {
// 		renderDialog(html)
// 	}, 500)
// }

// // Share 
// function shareButton() {
// 	// let link_to_share = getShareLink('copylink');
// 	sendClickShareGA('copylink');
// 	// copyToClipboard(link_to_share);
// 	// make_copy_button(document.getElementById("text-to-copy"));

// 	let html = 
// 		`<div class="overlay"></div>` +
// 		`<div class="dialog">` +
// 			`<div class="dialog__share">` +
// 				`<div class="dialog__share--text">` +
// 					`<div class="dialog__share--text-title">Oke Sip! Link udah di copy!</div>` +
// 					`<div>Tinggal kamu paste / tempel di Facebook kamu :)</div>` +
// 				`</div>` +
// 				`<div class="tg__button btn--animate" onclick="closeDialog('')">Oke</div>` +
// 			`</div>` +
// 		`</div>`
// 	;

// 	setTimeout(() => {
// 		renderDialog(html);
// 	}, 500);
// }

function getShareTextTemplate(isAtQuestion = false) {
	return template.page.share
		.replace("$shareFbClick", `doShare('${SocMed.FACEBOOK}', ${isAtQuestion})`)
		.replace("$shareLineClick", `doShare('${SocMed.LINE}', ${isAtQuestion})`)
		.replace("$shareTwClick", `doShare('${SocMed.TWITTER}', ${isAtQuestion})`)
		.replace("$shareWaClick", `doShare('${SocMed.WHATSAPP}', ${isAtQuestion})`)
		.replace("$share_fb_image_url", FacebookImage)
		.replace("$share_line_image_url", LineImage)
		.replace("$share_tw_image_url", TwitterImage)
		.replace("$share_whatsapp_image_url", WhatsappImage);
}

function doShare(medium, isAtQuestion){
	sendClickShareGA(medium);

	let questionId = isAtQuestion ? thisPage.currentQuestionID : 0;
	postVisitorLog(`click-share-${currentCategory.identifier}-${medium}-${questionId}`);
	let url = isAtQuestion ? shareLink.question[medium] : shareLink.general[medium];
	window.open(url, "_blank");
}

function setupShareLink(){
	renderTransparentLoading();
	generateShareLink(false);
	closeModal();
}

function generateShareLink(isAtQuestion = false) {
	Object.values(SocMed).forEach((medium) => {
		let questionId = isAtQuestion ? thisPage.currentQuestionID : 0;
		let shareData = getShareData(isAtQuestion);
		let socialImageLink = shareData.image.replace("[ID]", isAtQuestion ? questionId : currentCategory.id);
		let identifier = generateSharedIdentifier(medium, questionId);
		generateDynamicLink({
			identifier,
			socialTitle: shareData.shareText,
			socialDescription: shareData.description,
			socialImageLink
		})
			.then(response => { return response.json(); })
			.then(json => {
				let link = json.shortLink ? json.shortLink : targetRawLink;
				let generatedUrl = combineDynamicLinkWithItsMedium(medium, shareData.shareText, link);
				setShareLink(generatedUrl, isAtQuestion, medium);
			}).catch(function(error) {
				console.log("generateDynamicLink failed: ", error);
				let generatedUrl = combineDynamicLinkWithItsMedium(medium, shareData.shareText, targetRawLink);
				setShareLink(generatedUrl, isAtQuestion, medium);
			});
	});
}

function setShareLink(link, isAtQuestion, medium) {
	if (isAtQuestion) {
		shareLink.question[medium] = link;
	} else {
		shareLink.general[medium] = link;
	}
}

function combineDynamicLinkWithItsMedium(medium, text, link) {
	switch (medium) {
		case SocMed.FACEBOOK:
			return `https://www.facebook.com/dialog/share?app_id=397533753729282&display=touch`
				+ `&quote=${encodeURIComponent(text + " " + link)}`
				+ `&href=${link}`;
		case SocMed.TWITTER:
			return `https://twitter.com/intent/tweet?text=${encodeURIComponent(text + " " + link)}`;
		case SocMed.WHATSAPP:
			// https://api.whatsapp.com/send?text
			return `https://api.whatsapp.com/send?text=${encodeURIComponent(text + " " + link)}`;
			// return `https://wa.me?text=${encodeURIComponent(text + " " + link)}`;
			// return `https://wa.me?text=${encodeURIComponent(text)}`;
		case SocMed.LINE:
			return `https://line.me/R/msg/text/?${encodeURIComponent(text + " " + link)}`;
		case SocMed.FACEBOOK:
			return `https://www.facebook.com/dialog/share?app_id=397533753729282&display=touch`
				+ `&quote=${encodeURIComponent(text + " " + link)}`
				+ `&href=${link}`;
		default:
			return `https://twitter.com/intent/tweet?text=${encodeURIComponent(text + " " + link)}`;
	}
}

function generateSharedIdentifier(medium, questionId = 0) {
	let identifier = `click-share`;
	identifier += `-${currentCategory.identifier ? currentCategory.identifier : "identifier"}`;
	identifier += `-${currentCategory.id}`;
	identifier += `-${medium}`;	
	identifier += `-${questionId}`;
	identifier += `-${participant.client_id}`;
	return identifier;
}

function getShareData(isAtQuestion) {
	if (isAtQuestion) {
		return {
			shareText: "Bantuin jawab tebak gambar ini donk! Tebak Gambar x @komikbonju #BawaSemangat",
			image: "https://static.tebakgambar.com/TG-Lite/01062020-BawaSemangat/share/question/[ID].png",
			title: "Ayo ikutan main juga!",
			description: "Bantu aku, biar bisa jawab soal ini dan bawa semangat buat kamu!"
		}
	} else {
		return {
			shareText: "Hai, pesan #BawaSemangat ini khusus ku berikan untuk kamu!",
			image: "https://static.tebakgambar.com/TG-Lite/01062020-BawaSemangat/share/game/[ID].png",
			title: "Ayo ikutan main juga!",
			description: "Bantu aku #BawaSemangat,kamu juga bisa Bawa Hadiah di Tebak Gambar!"
		}
	}
}

function getToken() {
	let token = findGetUrlParameter("token");
	if (!token) {
		return showUpdateApps();
	}
	return token;
}

function showUpdateApps() {
	renderModal(
		"Update aplikasi Tebak Gambar kamu untuk memainkan level ini.",
		"Update Sekarang!",
		`goToUrl('http://blog.tebakgambar.com/download');`
	);
}

function showReload() {
	$('.tg__image--placeholder').hide();
	$(".tg__reload").css("display", "flex");
	$(".tg__image img").css("display", "none");
}

function reloadQuestionImage(imageUrl) {
	$(".tg__reload").css("display", "none");
	$('.tg__image--placeholder').show();
	$(".tg__image img").attr("src", imageUrl);
}

function sendVisitorLog() {
	let identifier = findGetUrlParameter("from_identifier") ? base32.decode(findGetUrlParameter("from_identifier")) : currentCategory.identifier;
	postVisitorLog(identifier);
}

window.tgwebversion = '0.2.1';
window.renderDialog = renderDialog;
window.closeDialog = closeDialog;
window.renderWelcome = renderWelcome;
window.renderQuestion = renderQuestion;
window.checkAnswer = checkAnswer;
window.goToUrl = goToUrl;
window.removePlaceholder = removePlaceholder;
window.allQuestionsAnsweredAndSumbangAmal = allQuestionsAnsweredAndSumbangAmal;
window.closeModal = closeModal;
window.fetchJson = fetchJson;
window.getShareTextTemplate = getShareTextTemplate;
window.luckyDrawClicked = luckyDrawClicked;
window.modalButtonClicked = modalButtonClicked;
window.reloadQuestionImage = reloadQuestionImage;
window.handleCheckLuckyDraw = handleCheckLuckyDraw;
window.renderLuckyDrawPage = renderLuckyDrawPage;
window.renderLoading = renderLoading;
window.renderModal = renderModal;

window.showReload = showReload;
window.tncClick = tncClick;
window.doShare = doShare;
window.sendClickOnInputGA = sendClickOnInputGA;
window.sendClickTryAgainGA = sendClickTryAgainGA;
window.sendClickContinueGA = sendClickContinueGA;

export { currentCategory, participant, thisPage };
