import {
	PAGE_TYPE_ENDING,

	currentCategory,
	participant,
	thisPage,
} from './index';

export function sendWelcomeScreenviewGA(callback) {
	// window.gtag('event', 'screen_view', {
	// 	'app_name': 'm.tebakgambar.com',
	// 	'screen_name': `welcome-${currentCategory.id}`,
	// 	'event_callback': function() {
	// 		if (callback && typeof(callback) === "function") {
	// 			callback();
	// 		}
	// 	}
	// });

	window.gtag('event', `home-${currentCategory.id}`, {
		'event_category': 'screenview',
		'identifier': `${currentCategory.identifier}`,
		'userid': `${participant.user_id}`,
		'categoryid': `${currentCategory.id}`,
		'event_callback': function() {
			if (callback && typeof(callback) === "function") {
				callback();
			}
		}
	});
}

export function sendGameplayScreenGA() {
	// window.gtag('event', 'screen_view', {
	// 	'app_name': 'm.tebakgambar.com',
	// 	'screen_name': `gameplay-${currentCategory.id}`
	// });

	window.gtag('event', `q-${thisPage.questionCount}-${currentCategory.id}-${thisPage.currentQuestionID}`, {
		'event_category': 'screenview',
		'identifier': `${currentCategory.identifier}`,
		'userid': `${participant.user_id}`,
		'categoryid': `${currentCategory.id}`,
	});
}

export function sendEndingScreenviewGA() {
	// window.gtag('event', 'screen_view', {
	// 	'app_name': 'm.tebakgambar.com',
	// 	'screen_name': `ending-${currentCategory.id}`,
	// });

	window.gtag('event', `ending-${currentCategory.id}`, {
		'event_category': 'screenview',
		'identifier': `${currentCategory.identifier}`,
		'userid': `${participant.user_id}`,
		'categoryid': `${currentCategory.id}`,
	});
}

export function sendLoadingScreenviewGA() {
	window.gtag('event', 'screen_view', {
		'app_name': 'm.tebakgambar.com',
		'screen_name': `loading-${currentCategory.id}`,
	});
}

export function sendErrorScreenviewGA() {
	window.gtag('event', 'screen_view', {
		'app_name': 'm.tebakgambar.com',
		'screen_name': `error-${currentCategory.id}`,
	});
}

export function sendWelcomeEventGA(button_title) {
	window.gtag('event', 'click on start', {
		'event_category': 'welcome page',
		'event_label': `${currentCategory.id} - ${button_title}`,
		'identifier': `${currentCategory.identifier}`,
		'userid': `${participant.user_id}`,
		'categoryid': `${currentCategory.id}`,
		'questionid': '',
	});
}

export function sendClickShareGA(socmed) {
	let eventCategory = thisPage.currentPage == PAGE_TYPE_ENDING ? 'ending page' : 'gameplay page';
	window.gtag('event', 'click on share', {
		'event_category': eventCategory,
		'event_label': `${socmed} - ${currentCategory.id} - ${thisPage.currentQuestionID}`,
		'identifier': `${currentCategory.identifier}`,
		'userid': `${participant.user_id}`,
		'categoryid': `${currentCategory.id}`,
		'questionid': `${thisPage.currentQuestionID}`,
	});
}

export function sendClickOnInputGA() {
	window.gtag('event', 'click on input', {
		'event_category': 'gameplay page',
		'event_label': `${currentCategory.id} - ${thisPage.currentQuestionID}`,
		'identifier': `${currentCategory.identifier}`,
		'userid': `${participant.user_id}`,
		'categoryid': `${currentCategory.id}`,
		'questionid': `${thisPage.currentQuestionID}`,
	});
}

export function sendClickOnAnswerGA(trueOrFalse) {
	window.gtag('event', 'click on answer', {
		'event_category': 'gameplay page',
		'event_label': `${currentCategory.id} - ${thisPage.currentQuestionID} - ${trueOrFalse}`,
		'identifier': `${currentCategory.identifier}`,
		'userid': `${participant.user_id}`,
		'categoryid': `${currentCategory.id}`,
		'questionid': `${thisPage.currentQuestionID}`,
	});
}

export function sendClickTryAgainGA() {
	window.gtag('event', 'click on try again', {
		'event_category': 'error page',
		'event_label': `${currentCategory.id} - ${thisPage.currentQuestionID}`,
		'identifier': `${currentCategory.identifier}`,
		'userid': `${participant.user_id}`,
		'categoryid': `${currentCategory.id}`,
		'questionid': `${thisPage.currentQuestionID}`,
	});
}

export function sendClickContinueGA(button_title) {
	window.gtag('event', 'click on continue', {
		'event_category': 'ending page',
		'event_label': `${currentCategory.id} - ${button_title}`,
		'identifier': `${currentCategory.identifier}`,
		'userid': `${participant.user_id}`,
		'categoryid': `${currentCategory.id}`,
		'questionid': `${thisPage.currentQuestionID}`,
	});
}