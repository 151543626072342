/* Get URL Query string by param */
export function findGetUrlParameter(parameterName) {
	var result = null;
	var tmp = [];
	var items = location.search.substr(1).split("&");
	for (var index = 0; index < items.length; index++) {
		tmp = items[index].split("=");
		if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
	}
	return result;
}

export function isAtDownloadPage() {
	return location.href.indexOf("/download.html?") > -1;
}

export function setUrlParameter(paramName, paramValue) {
	let tmp = []
	let isExist = false
	let items = location.search.substr(1).split('&')

	if (!items || !items.length) {
		return ''
	}

	for (var index = 0; index < items.length; index++) {
		tmp = items[index].split('=')
		if (tmp[0] === paramName) {
			tmp[1] = paramValue
			items[index] = tmp.join('=')
			isExist = true
			break
		}
	}

	if (!isExist) {
		items.push(`${paramName}=${paramValue}`)
	}
	
	return items.join('&')
}

export function getClientCookie(name) {
	var value = "; " + document.cookie;
	var parts = value.split("; " + name + "=");
	if (parts.length == 2) return parts.pop().split(";").shift();
}

export function setClientCookie(name, value, days) {
	var d = new Date();
	d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
	document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
}

export function shuffleArray(array) {
	if (!array || array.length == 0) { return []; }

	var currentIndex = array.length, temporaryValue, randomIndex;
  
	// While there remain elements to shuffle...
	while (0 !== currentIndex) {
  
		// Pick a remaining element...
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex -= 1;

		// And swap it with the current element.
		temporaryValue = array[currentIndex];
		array[currentIndex] = array[randomIndex];
		array[randomIndex] = temporaryValue;
	}
  
	return array;
}

export const replaceStringAt = (word, index, value) => {
	let split = word.split('')

	if (split.length >= index) {
		split[index] = value
	}

	return split.join('')
}

export const getHintAnswer = (word) => {
	const underscoreHint = word.replace(/[a-zA-Z]/g, '_')
	const randomInt = randomIntFromInterval(0, word.length - 1)
	const hint = word[randomInt]

	// set the hint on specific place
	const generatedHint = replaceStringAt(underscoreHint, randomInt, hint)
	return generatedHint
}

export const randomIntFromInterval = (min, max) => {
	return Math.floor(Math.random() * (max - min + 1) + min)
}

export const copyToClipboard = str => {
	const el = document.createElement("textarea");
	el.value = str;
	el.setAttribute("readonly", "");
	el.style.position = "absolute";

	document.body.appendChild(el);
	el.select();
	document.execCommand("copy");
	document.body.removeChild(el);
};
