import $ from "jquery";
import {findGetUrlParameter} from './helper';
import {
    currentCategory,
    participant,
    thisPage,
} from "./index";
import { base32 } from "./base32";

let BASE_ENDPOINT = "https://api.tebakgambar.com";
let FIR_WEB_API_KEY = "AIzaSyDOqaPjs7ViQ4Q00IuLNu-lxareTJWkkjk";

export function fetchAlltoCache(arrayOfUrls) {
	let cacheName = 'tg-web-app';
	caches.open(cacheName).then(cache => {
		return cache.addAll(arrayOfUrls)
	})
}

function fetchAndPutCache(url, cacheName, token) {
	return new Promise((resolve, reject) => {
		// Set timeout timer
		let timer = setTimeout(
			() => reject( new Error('Request timed out') ),
			15 * 1000, // timeout in seconds
		);

		let myHeaders = new Headers();		
		myHeaders.append("Authorization", `Bearer ${token}`);
		myHeaders.append("Content-Type", "application/json");

		window.fetch(url, {
			method: "GET",
			headers: myHeaders
		}).then(
			response => {
				let res = response.clone()
				if (res.status != 200) {
					reject( new Error(`[${res.status}] Failed on fetching json.`))
				}

				if ('caches' in window) {
					caches.open(cacheName).then(cache => {
						cache.put(url, response)
					})
				}

				resolve( res )

			}, err => reject( err ),
		).finally( () => clearTimeout(timer) );
	});
}

export function getJson(obj) {
	let formatDate = `?date=${new Date().getTime()}`;
	let token = obj && obj.token ? obj.token : '';
	let identifier = obj && obj.identifier ? `&identifier=${obj.identifier}` : '';

	let url = BASE_ENDPOINT + '/pwa/v1/lucky_draw' + formatDate + identifier;
	let cacheName = 'tg-web-app';

	// returns immediately if it doesnt support cache API
	if (!('caches' in window)) {
		return fetchAndPutCache(url, cacheName, token)
	}

	return caches.open(cacheName).then(cache => {
		return cache.match(url).then(res => {
			let is_using_cache = findGetUrlParameter('json_cache') == 'false' ? false : true;
			if (res && is_using_cache) return new Promise((resolve) => {
				resolve(res)
			});

			return fetchAndPutCache(url, cacheName, token)
		})
	})
}

export function postVisitorLog(identifier) {
	let formData = new FormData();
	formData.append('identifier', identifier);
	formData.append('user_id', participant.user_id);
	formData.append('client_id', participant.client_id);
	formData.append('user_full_name', participant.name);
	formData.append('category_id', currentCategory.id);

	// let uri = 'https://api-lite.tebakgambar.com/pwa/logger/visit';
	let uri = 'https://api-lite.tebakgambar.com/logVisit.php';
	return window.fetch(uri, {
		method: "POST",
		body: formData,
	});
}

export function postAnswerLog(isTrueOrFalse) {
    let formData = new FormData();
    formData.append('identifier', currentCategory.identifier);
    formData.append('user_id', participant.user_id);
    formData.append('client_id', participant.client_id);
    formData.append('user_full_name', participant.name);
    formData.append('category_id', currentCategory.id);
    formData.append('question_id', thisPage.currentQuestionID);
    formData.append('is_answer_right', isTrueOrFalse);

    // let uri = 'https://api-lite.tebakgambar.com/pwa/logger/answer';
    let uri = 'https://api-lite.tebakgambar.com/logAnswer.php';
    return window.fetch(uri, {
        method: "POST",
		body: formData,
    });
}

export function checkLuckyDraw(token = '', category_id = 0) {
	const uri = `${BASE_ENDPOINT}/pwa/v1/lucky_draw/check`;
	return window.fetch(uri, {
		method: 'POST',
		body: JSON.stringify({ category_id }),
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	});
}

export function postSumbangAmal(obj) {
	let categoryId = obj.categoryId ? obj.categoryId : '';
	let token = obj.token ? obj.token : '';
	
	let myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Content-Type", "application/json");

	let raw = JSON.stringify({"category_id":categoryId});

	let requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: raw
	};

	return window.fetch(BASE_ENDPOINT + "/pwa/v1/lucky_draw", requestOptions);
}

export function generateDynamicLink(obj) {
	let identifier = base32.encode(obj.identifier);
	let link = generateRawSharedLink(identifier);
	let socialTitle = obj.socialTitle ? obj.socialTitle : '';
	let socialDescription = obj.socialDescription ? obj.socialDescription : '';
	let socialImageLink = obj.socialImageLink ? obj.socialImageLink : '';
	let fallbackLink = 'http://m.tebakgambar.com/download.html?from_identifier=' + identifier;
	var headers = new Headers();
	headers.append("Content-Type", "application/json");
	var body = JSON.stringify({
		"dynamicLinkInfo": {
			"domainUriPrefix": "https://maintebakgambar.page.link",
			"link": link,
			"androidInfo": {
				"androidPackageName": "com.tebakgambar",
				"androidFallbackLink": fallbackLink
			},
			"iosInfo": {
				"iosBundleId": "com.tebakgambar.Tebak-Gambar",
				"iosFallbackLink": fallbackLink
			},
			"socialMetaTagInfo": {
				"socialTitle": socialTitle,
				"socialDescription": socialDescription,
				"socialImageLink": socialImageLink
			}
		}
	});
	var requestOptions = {
		method: 'POST',
		headers,
		body
	};

	return window.fetch("https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key="+ FIR_WEB_API_KEY, requestOptions);
}

function generateRawSharedLink(identifier) {
	let prefixUrl = `https://m.tebakgambar.com?from_identifier=`;
	return `https://tebakgambar.com/webview?url=` + encodeURIComponent(prefixUrl) + base32.encode(identifier);
}